import React from 'react'
import { useState } from 'react';
import "./Login.scss";
import {DataContext} from "../../UserDataContext";
import {Link} from "react-router-dom";
import { useContext } from 'react';
import { auth } from '../../firebase';
import { SnackbarContext } from 'components/SnackBarProvider';

export interface Props {
    [key: string]: any
}


const Login = ({history}: Props) => {
    const userData = useContext (DataContext);
    const [hidden, setHidden] = useState(true);
    const [formData, setFormData] = useState({email: "", password: ""});
    const {setData: setSnack} = useContext(SnackbarContext);

    const handleFormChange = (key: string) => (e: any) => {
        setFormData({...formData, [key]: e.target.value})
    }

    const togglePassword = () => {
        setHidden(!hidden);
    }

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await auth.signInWithEmailAndPassword(formData.email, formData.password);
            setSnack({
                message: "Logged in successfully.",
                type: "success"
            });
            setTimeout(() => history.push("/dashboard"), 1000);
        }
        catch (e) {
            setSnack({
                message: e.message,
                type: "error"
            })
        }
    }

    return (
        <div className="auth">
            <div className="header">
                <p>Let's Start with Login!</p>
            </div>
            <form onSubmit = {login}>
                <div className="input-wrapper">
                    <i className="fas fa-at"></i>
                    <input onChange={handleFormChange("email")} type="email" required placeholder="Email"/>
                </div>
                <div className="input-wrapper">
                    <i className="fas fa-lock"></i>
                    <input onChange={handleFormChange("password")} type = {hidden ? "password" : "text"} required placeholder="Password"/>
                    {hidden ? 
                        <i className="fas fa-eye" onClick = {togglePassword}/> : 
                        <i className="fas fa-eye-slash" onClick = {togglePassword}></i>
                    }
                </div>
                <input type = "submit" value = "Login"/>
            </form>
            <Link to = "/forgot" className = "mirror">Forgot Password?</Link>
            <Link to = "/signup" className = "mirror">Create Account</Link>
        </div>
    );
}

export default Login;