import { auth } from "./firebase";
import React, { useEffect, useState } from "react";
import { getDriverData } from "api";

interface indexable {
    [key: string]: any
}

interface Data {
    [key: string]: any,
    userData: null | indexable,
    authData: null | FirebaseProfile | undefined,
    populateUserData: () => any,
}

interface FirebaseProfile {
    displayName: string
}

export const DataContext = React.createContext <Data> ({
    userData: null,
    authData: null,
    populateUserData: () => null,
});

export const DataProvider = ({ children, ...props }: {children: any}) => {
    const [userData, setUserData] = useState <null | object> (null);
    const [authData, setAuthData] = useState <null | FirebaseProfile | undefined> (undefined);

    const populateUserData = () => {
        if (authData){
            getDriverData({username: authData.displayName}).then(x => {
                setUserData(x);
            })
        }
    }

    auth.onAuthStateChanged((user: any) => {
        setAuthData(user)
    });


    return (
        <DataContext.Provider
            value={{ 
                userData,
                authData,
                populateUserData,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};