import React, { useContext } from 'react'
import { DataContext } from 'UserDataContext';
import "./Dashboard.scss";

export interface Props {
    [key: string]: any
}

const Dashboard = ({history}: Props) => {
    const {userData, populateUserData} = useContext(DataContext) as any;
    
    if (!userData) {
        populateUserData();
        return <></>;
    }

    return (
        <div className="dashboard">
            <div className="info">
                <img src="https://www.urbansplash.co.uk/images/placeholder-1-1.jpg" alt="profile picture"/>
                <p className="name">{userData.name}</p>
                <p className="adress">{userData.timezone}</p>
            </div>
            <div className="bottom">
                <div className="tickets">
                    <div>
                        <p className="counter">3</p>
                        <p className="label">Open Tickets</p>
                    </div>
                    <div>
                        <p className="counter">2</p>
                        <p className="label">Pending Tickets</p>
                    </div>
                    <div>
                        <p className="counter">5</p>
                        <p className="label">Closed Tickets</p>
                    </div>
                </div>
                <button className = "add-ticket" onClick = {() => history.push("/new-ticket")}>New Ticket</button>
            </div>
        </div>
    );
}

 export default Dashboard;