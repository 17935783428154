import React, { useEffect } from 'react'
import { useState } from 'react';
import { Ticket } from 'utils/interfaces';
import usePersistentState from 'utils/usePersistentState';
import Metadata from './Metadata';
import "./AddTicket.scss"
import { useCallback } from 'react';
import Checkbox from './Checkbox';

export interface Props {
    [key: string]: any
}

const defaultData: Ticket = {
    metadata: {
        company: "",
        dispatch: "",
        afe: "",
        rrc: "",
        lease: "",
        well: "",
        company_man: "",
        phone: "",
        authorized_by: "",
        origin: "",
        rig: ""
    },
    checks: {
        lifting_hazards: false,
        fall_potential: false,
        electrical_shock: false,
        overhead_lines: false,
        elevated: false,
        sse: false,
        pinch_points: false,
        potential_releases: false,
        hot_work_permit: false,
        lock_out_tag_out: false,
        cable_sling_inspection: false,
        environment_extremes: false,
        pollution_prevention: false,
        maneuvering_safety: false,
        others_on_site: false,
        fire_potential: false,
        h2s_exposure: false,
        correct_ppe: false
    }
};

const Pages = [Metadata, Checkbox];

const AddTicket = (props: Props) => {
    const [page, setPage] = usePersistentState <number> ("add-ticket-page", 0);
    const [data, setData] = usePersistentState <Ticket> ("add-ticket-data", defaultData);

    const updateFormData = (parent: string, key: string) => (e: any) => {
        setData({...data, [parent]: {...data[parent], [key]: e.target.value}});
    };

    const updateCheckData = (key: string) => () => {
        setData({...data, checks: {...data.checks, [key]: !data.checks[key]}})
    }

    const changePage = (newPage: number) => {
        if (newPage < 0 || newPage >= Pages.length){
            return;
        }
        setPage(newPage);
    }

    const CurrentPage = Pages[page];

    return (
        <div className="add-ticket">
            <div className = "form-page">
                <div className = "controllers top">
                    <button onClick = {() => changePage(page - 1)}>
                        <i className = "fas fa-chevron-left"></i>
                    </button>
                    <button onClick = {() => changePage(page + 1)}>
                        <i className = "fas fa-chevron-right"></i>
                    </button>
                </div>
                <CurrentPage updateFormData = {updateFormData} data = {data} updateCheckData = {updateCheckData}/>
                <div className = "controllers">
                    <button onClick = {() => changePage(page - 1)}>
                        <i className = "fas fa-chevron-left"></i>
                    </button>
                    <button onClick = {() => changePage(page + 1)}>
                        <i className = "fas fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

 export default AddTicket;