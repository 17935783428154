import React from 'react'
import {Link} from "react-router-dom";
import "./Navbar.scss";

export interface Props {
    logged: boolean
}

const Navbar = ({logged}: Props) => {

    return (
        <div className = "navbar">
            <Link to = "/" className = "app-name">Samsara</Link>
            {logged && <div>
                <Link to = "/dashboard">Dashboard</Link>
                <Link to = "/profile">Profile</Link>
                <Link to = "/settings">Settings</Link>
                <Link to = "/logout">Logout</Link>
            </div>}
        </div>
    );
}

 export default Navbar;