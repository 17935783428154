import { auth } from '../../firebase';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { DataContext } from 'UserDataContext';
import { SnackbarContext } from 'components/SnackBarProvider';

export interface Props {
    [key: string]: any
}

const Forgot = ({history}: Props) => {
    const {setData: setSnack} = useContext(SnackbarContext);
    const userData = useContext (DataContext);
    const [email, setEmail] = useState("");


    const forgot = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await auth.sendPasswordResetEmail(email);
            setSnack({
                message: "Email instructions sent successfully.",
                type: "success"
            });
        }
        catch (e) {
            setSnack({
                message: e.message,
                type: "error"
            })
        }
    }

    return (
        <div className="auth">
            <div className="header">
                <p>Let's get your password back!</p>
            </div>
            <form onSubmit = {forgot}>
                <div className="input-wrapper">
                    <i className="fas fa-at"></i>
                    <input onChange={(e) => setEmail(e.target.value)} type="email" required placeholder="Email"/>
                </div>
                <input type = "submit" value = "Send Email"/>
            </form>
            <Link to = "/login" className = "mirror">Log In</Link>
            <Link to = "/signup" className = "mirror">Create Account</Link>
        </div>
    );
}

 export default Forgot;