import React, { useContext } from 'react'
import { DataContext } from 'UserDataContext';
import "./Profile.scss";

export interface Props {
    [key: string]: any
}

const Profile = (props: Props) => {
    const {userData: userData, authData, populateUserData} = useContext (DataContext);
    console.log(userData);

    if (!userData) {
        populateUserData();
        return <></>;
    }

    const data = [
        {
            label: "Name",
            value: userData!.name,
        },
        {
            label: "Username",
            value: authData!.displayName,
        },
        {
            label: "Email",
            value: userData!.email,
        },
        {
            label: "License State",
            value: userData!.licenseState,
        },
        {
            label: "Timezone",
            value: userData!.timezone,
        },
        {
            label: "ID",
            value: userData!.id,
        },
        {
            label: "Carrier Name",
            value: userData!.carrierSettings.carrierName,
        },
        
    ]

    return (
        <div className = "profile">
            <p className = "title">Here is what we know about you:</p>
            <div className = "info">
                {data.map(x => (
                    <div className = "info-box">
                        <div>
                            <p className = "label">{x.label}:</p>
                            <p className = "value">{x.value}</p>
                        </div>
                    </div>
                ))}
                </div>
        </div>
    );
}

 export default Profile;