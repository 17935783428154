import React from 'react'
import { Ticket } from 'utils/interfaces';

export interface Props {
    [key: string]: any,
    updateFormData: (parent: string, key: string) => (e: any) => void,
    data: Ticket
}

const Metadata = ({updateFormData, data}: Props) => {

    return (
        <div className = "metadata">
            <p className = "title">Meta Data (1 / 4)</p>
            <div className = "row">
                <div className = "input-box">
                    <p className = "label">Company:</p>
                    <input onChange = {updateFormData("metadata", "company")} value = {data.metadata.company}/>
                </div>
                <div className = "input-box">
                    <p className = "label">Dispatch:</p>
                    <input onChange = {updateFormData("metadata", "dispatch")} value = {data.metadata.dispatch}/>
                </div>
            </div>
            <div className = "row">
                <div className = "input-box">
                    <p className = "label">PO.WA. AFE:</p>
                    <input onChange = {updateFormData("metadata", "afe")} value = {data.metadata.afe}/>
                </div>
                <div className = "input-box">
                    <p className = "label">RRC:</p>
                    <input onChange = {updateFormData("metadata", "rrc")} value = {data.metadata.rrc}/>
                </div>
            </div>
            <div className = "row">
                <div className = "input-box">
                    <p className = "label">Lease:</p>
                    <input onChange = {updateFormData("metadata", "lease")} value = {data.metadata.lease}/>
                </div>
                <div className = "input-box">
                    <p className = "label">Well:</p>
                    <input onChange = {updateFormData("metadata", "well")} value = {data.metadata.well}/>
                </div>
            </div>
            <div className = "row">
                <div className = "input-box">
                    <p className = "label">Company man:</p>
                    <input onChange = {updateFormData("metadata", "company_man")} value = {data.metadata.company_man}/>
                </div>
                <div className = "input-box">
                    <p className = "label">Phone:</p>
                    <input onChange = {updateFormData("metadata", "phone")} value = {data.metadata.phone}/>
                </div>
            </div>
            <div className = "input-box">
                <p className = "label">Authorized by:</p>
                <input onChange = {updateFormData("metadata", "authorized_by")} value = {data.metadata.authorized_by}/>
            </div>
            <div className = "row">
                <div className = "input-box">
                    <p className = "label">Origin:</p>
                    <input onChange = {updateFormData("metadata", "origin")} value = {data.metadata.origin}/>
                </div>
                <div className = "input-box">
                    <p className = "label">Rig:</p>
                    <input onChange = {updateFormData("metadata", "rig")} value = {data.metadata.rig}/>
                </div>
            </div>
        </div> 
    );
}

 export default Metadata;