import { sendCode, updateDriverData, validateCode } from 'api';
import React, { useRef } from 'react'
import { useState } from 'react';
import "./Login.scss";
import {DataContext} from "../../UserDataContext";
import { useContext } from 'react';
import { auth } from '../../firebase';
import { SnackbarContext } from 'components/SnackBarProvider';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography } from '@material-ui/core';
import { useEffect } from 'react';

export interface Props {
    [key: string]: any
}

interface FormDataType {
    username: string,
    password: string,
    email: string,
    phoneNumber: string;
}

const useInterval = (initialValue: number): [number, (x: number) => void] => {
    const [data, setData] = useState <number> (initialValue);
    const ref = useRef <number> (initialValue);

    useEffect(() => {
        let i = setInterval(() => {
            setData(ref.current - 1);
            ref.current--;
        }, 1000)
        return () => clearInterval(i);
    }, [])

    const setValue = (newVal: number) => {
        setData(newVal)
        ref.current = newVal;
    }

    return [data, setValue];
}

const Signup = ({history}: Props) => {
    const userData = useContext (DataContext);
    const [hidden, setHidden] = useState(true);
    const [formData, setFormData] = useState <FormDataType> ({username: "", password: "", email: "", phoneNumber: ""});
    const {setData: setSnack} = useContext(SnackbarContext);
    const [dialog, setDialog] = useState(false);
    const [code, setCode] = useState("");
    const wait = 5 * 60;
    const [resend, setResend] = useInterval(0);

    const handleFormChange = (key: string) => (e: any) => {
        setFormData({...formData, [key]: e.target.value})
    }

    const handlePhoneNumber = (newVal: any) => {
        setFormData({...formData, phoneNumber: newVal});
    }

    const togglePassword = () => {
        setHidden(!hidden);
    }

    const handleClose = () => {
        setDialog(!!0);
        setCode("");
    }

    const signup = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (resend > 0) {
            setSnack({
                message: `You have to wait ${Math.floor(resend / 60)}:${resend % 60} minutes before sending another code.`,
                type: "warning"
            })
            return;
        }
        if (!formData.phoneNumber || !isValidPhoneNumber(formData.phoneNumber)) {
            setSnack({
                message: "Phone number is not valid.",
                type: "error"
            })
            return;
        }
        try {
            let res = await sendCode({username: formData.username, phoneNumber: formData.phoneNumber});
            setSnack({
                message: res.message,
                type: "success"
            })
            setResend(wait);
            setDialog(true);
        }
        catch (e) {
            setSnack({
                message: e.message,
                type: "error"
            })
        }
    }

    const resendCode = async () => {
        try{
            let res = await sendCode({username: formData.username, phoneNumber: formData.phoneNumber});
            setSnack({
                message: res.message,
                type: "success"
            })
            setResend(wait);
        }
        catch (e) {
            setSnack({
                message: e.message,
                type: "error"
            })
        }
    }

    const trySign = async () => {
        if (!code || code.length != 6) {
            setSnack({
                message: "Code format is invalid.",
                type: "error"
            })
            setCode("");
        }
        else {
            let res = await validateCode({phoneNumber: formData.phoneNumber, code});
            if (res.authorization === "rejected") {
                setSnack({
                    message: res.message,
                    type: "error"
                })
                setCode("");
            }
            else {
                try {
                    let authData = await auth.createUserWithEmailAndPassword(formData.email, formData.password) as any;
                    await authData.user.updateProfile({displayName: `${formData.username}`});
                    await updateDriverData(formData);
                    setSnack({
                        message: "Account created successfully.",
                        type: "success"
                    })
                    history.push("/dashboard");
                }
                catch (e) {
                    setSnack({
                        message: e.message,
                        type: "error"
                    })
                    setCode("");
                }
            }
        }
    }

    return (
        <>
            <div className="auth">
                <div className="header">
                    <p>Let's Create An Account!</p>
                </div>
                <form onSubmit = {signup}>
                <div className="input-wrapper">
                        <i className="fas fa-at"></i>
                        <input value={formData.email} onChange={handleFormChange("email")} type="email" required placeholder="Email"/>
                    </div>
                    <div className="input-wrapper">
                        <i className ="fas fa-user"></i>
                        <input value={formData.username} onChange={handleFormChange("username")} type="username" required placeholder="Username"/>
                    </div>
                    <div className="input-wrapper">
                        <PhoneInput defaultValue={undefined} defaultCountry = "US" className = "phone-input" value = {formData.phoneNumber as string} onChange = {handlePhoneNumber} placeholder = "Ex: (541) 754-3010"/>
                    </div>
                    <div className="input-wrapper">
                        <i className="fas fa-lock"></i>
                        <input value={formData.password} onChange={handleFormChange("password")} type = {hidden ? "password" : "text"} required placeholder="Password"/>
                        {hidden ? 
                            <i className="fas fa-eye" onClick = {togglePassword}/> : 
                            <i className="fas fa-eye-slash" onClick = {togglePassword}></i>
                        }
                    </div>
                    <input type = "submit" value = "Signup"/>
                </form>
                <Link to = "/forgot" className = "mirror">Forgot Password?</Link>
                <Link to = "/login" className = "mirror">Log In</Link>
            </div>
            <Dialog className = "fixD" open={dialog} onClose={handleClose}>
                <DialogTitle id="form-dialog-title"></DialogTitle>
                <DialogContent>
                    <Typography variant = "h6">
                        To continue, please enter the 6-digit code sent at {formData.phoneNumber}:
                    </Typography>
                    <TextField
                        color="primary"
                        autoFocus
                        margin="dense"
                        fullWidth
                        value = {code}
                        onChange = {(e) => setCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={resendCode} disabled = {resend > 0} color="primary" variant = "outlined">
                        Resend code {resend > 0 ? `(${Math.floor(resend / 60)}:${resend % 60})` : ""}
                    </Button>
                    <Button onClick={trySign} color="primary" variant = "contained">
                        Sign In
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Signup;