import React, { useContext } from 'react'
import Splash from './splash/Splash'
import Login from "./login/Login";
import Background from './Background';
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import Dashboard from './dashboard/Dashboard';
import { DataContext, DataProvider } from 'UserDataContext';
import Signup from './login/Signup';
import { auth } from '../firebase';
import AddTicket from './ticket/AddTicket';
import Profile from './profile/Profile';
import Navbar from "./navbar/Navbar"
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Forgot from './login/Forgot';

export interface Props {
    [key: string]: any
}

interface RouteData {
    path: string,
    component: any,
    condition?: boolean,
    redirectUrl?: string,
    props?: object
}

const CustomRoute = ({path, condition, redirectUrl, component: Component}: RouteData) => {
	if (!condition) {
		return <Redirect exact from = {path} to = {redirectUrl as string}/>
	}
	return (
		<Route exact path = {path} render = {(props) => <Component {...props}/>}/>
	)
}

const Signout = ({history}: {history: any}) => {
    auth.signOut();
    history.push("/");
    console.log("logout");
    return null;
}

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: "#43D474",
            light: "#6ddd92"
        },
        secondary: {
            main: "#132922",
            light: "#282828"
        }
    },
  });

const App = (props: Props) => {
    const userData = useContext(DataContext);

    return (
        <ThemeProvider theme = {darkTheme}>
            {/* <Splash/> */}
            <Background/>
            <Router>
                {userData.authData === undefined ? 
                    <></> :
                    <>
                        <Navbar logged = {!!userData.authData}/>
                        <Switch>
                            <CustomRoute path = "/login" condition = {true} component = {Login}/>
                            <CustomRoute path = "/forgot" condition = {true} component = {Forgot}/>
                            <CustomRoute path = "/signup" condition = {true} component = {Signup}/>
                            <CustomRoute path = "/logout" condition = {true} component = {Signout}/>
                            <CustomRoute path = "/new-ticket" condition = {true} component = {AddTicket}/>
                            <CustomRoute path = "/profile" condition = {!!userData.authData} component = {Profile}/>
                            <CustomRoute path = "/dashboard" condition = {!!userData.authData} redirectUrl = "/login" component = {Dashboard}/>
                            <Redirect from = "/" to = {userData.authData ? "/dashboard" : "/login"}/>
                            <Redirect from = "*" to = "/"/>
                        </Switch>
                    </>
                }
            </Router>
        </ThemeProvider>
    );
}

 export default App;