import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCtFZ7dMgA9rtpS-W0x8yWjSZNKoa_FLUE",
    authDomain: "samsara-7eba8.firebaseapp.com",
    projectId: "samsara-7eba8",
    storageBucket: "samsara-7eba8.appspot.com",
    messagingSenderId: "1047739280476",
    appId: "1:1047739280476:web:087a7c86cfb14c07958a99",
    measurementId: "G-Y84BX07ND1"
};

let firebaseApp = firebase.initializeApp(firebaseConfig);
let db = firebaseApp.firestore();
let auth = firebaseApp.auth();

export {db, auth};