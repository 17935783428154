import React from 'react'
import { Ticket } from 'utils/interfaces';

export interface Props {
    [key: string]: any,
    updateFormData: (parent: string, key: string) => (e: any) => void,
    data: Ticket
}

const CheckboxElement = ({handler, checked}: {handler: () => void, checked: boolean}) => {

    return (
        <div className = "custom-checkbox" onClick = {handler}>
            {checked && <i className = "fas fa-check"/>}
        </div>
    )
}

const Checkbox = ({updateCheckData, data}: Props) => {

    return (
        <div className="checkbox-page">
            <p className = "title">Checks (2 / 4)</p>
            <div className = "row col-sep">
                <div className = "col">
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("lifting_hazards")} checked = {data.checks.lifting_hazards}/>
                        <p className="label">Lifting Hazzards</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("fall_potential")} checked = {data.checks.fall_potential}/>
                        <p className="label">Fall Potential</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("electrical_shock")} checked = {data.checks.electrical_shock}/>
                        <p className="label">Electrical Shock</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("overhead_lines")} checked = {data.checks.overhead_lines}/>
                        <p className="label">Overhead Lines</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("elevated")} checked = {data.checks.elevated}/>
                        <p className="label">Elevated Load / Work</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("sse")} checked = {data.checks.sse}/>
                        <p className="label">S.S.E</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("pinch_points")} checked = {data.checks.pinch_points}/>
                        <p className="label">Pinch Points</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("potential_releases")} checked = {data.checks.potential_releases}/>
                        <p className="label">Potential Releases</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("hot_work_permit")} checked = {data.checks.hot_work_permit}/>
                        <p className="label">How Work Permit</p>
                    </div>
                </div>
                <div className = "col">
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("lock_out_tag_out")} checked = {data.checks.lock_out_tag_out}/>
                        <p className="label">Lock out / Tag out</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("cable_sling_inspection")} checked = {data.checks.cable_sling_inspection}/>
                        <p className="label">Cable / Sling Inspection</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("environment_extremes")} checked = {data.checks.environment_extremes}/>
                        <p className="label">Environment Extremes</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("pollution_prevention")} checked = {data.checks.pollution_prevention}/>
                        <p className="label">Pollution Prevention</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("maneuvering_safety")} checked = {data.checks.maneuvering_safety}/>
                        <p className="label">Maneuvering Safety</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("others_on_site")} checked = {data.checks.others_on_site}/>
                        <p className="label">Other Parties on Site</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("fire_potential")} checked = {data.checks.fire_potential}/>
                        <p className="label">Fire Potential</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("h2s_exposure")} checked = {data.checks.h2s_exposure}/>
                        <p className="label">H2S Exposure</p>
                    </div>
                    <div className = "checkbox-box">
                        <CheckboxElement handler = {updateCheckData("correct_ppe")} checked = {data.checks.correct_ppe}/>
                        <p className="label">Correct P.P.E.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

 export default Checkbox;