const secureUrl = `https://${window.location.hostname}:8000`;
const insecureUrl = `http://${window.location.hostname}:8000`;
const testUrl = `http://dev-vanilla.com:8001`;
let url = insecureUrl;
// let url = process.env.NODE_ENV  === "development" ? insecureUrl : secureUrl;

const sendCode = async (sup : {phoneNumber: string, username:string})  => {
    const res = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sup)
    })
    const data = await res.json();
    if (res.status != 200) throw data;
    return data;
}

const validateCode = async (sup: {phoneNumber: string, code: string}) => {
    const res = await fetch(url + "/validate", {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sup)
    })
    const data = await res.json();
    if (res.status != 200) throw data;
    return data;
}

const updateDriverData = async (sup: {username: string, email: string, phoneNumber: string}) =>  {
    const res = await fetch(url + "/update", {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sup)
    })
    const data = await res.json();
    if (res.status != 200) throw data;
    return data;
}

const getDriverData = async (sup: {username: string}) => {
    const res = await fetch(url + "/driver", {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(sup)
    });
    const data = await res.json();
    if (res.status != 200) throw data;
    return data.data;
}

export {updateDriverData, getDriverData, sendCode, validateCode}; 