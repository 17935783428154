import React from 'react'
import {Helmet} from "react-helmet";

const Background = () => {
    return (
        <Helmet>
            <html className = "theme--dark"/>
        </Helmet>
    );
}

 export default Background;